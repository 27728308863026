import * as React from "react"
import { useState } from "react"
import { navigate } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => {

  const [seachInput, setSeachInput] = useState()

  const onClickHandler = (e) => {
    localStorage.setItem("search-input", seachInput);
    navigate('/search/')
  }

  const onChangeSearchHandler = (e) => {
    setSeachInput(e.target.value);
  }
  
  return (
    <Layout includeSearchBar={false}>
      <Seo title="Home" />
      <div className="bg-indigo-100 relative top-search-form">
        <div className="container max-w-6xl md:flex md:items-center px-6 py-20 pb-0">
          <div className="md:w-1/2">
            <h1 className="text-white text-2xl md:text-5xl mb-4 font-bold">
              Right Place for all your <br/><span className="text-orange text-2xl md:text-6xl">Queries</span>
            </h1>
            <p className="text-white">
              Explore and know more about Recognition, Pulse, Perks and Fit.
            </p>
            <div className="flex flex-col md:flex-row relative sm:w-full">
              <input
                placeholder="Search Help Topics.."
                style={{ paddingLeft: "3rem" }}
                className="md:w-7/12 w-4/6 mr-2 vc-text-input pl-44"
                type="text"
                onChange={onChangeSearchHandler}
                onKeyPress={(e) => e.key == "Enter" && onClickHandler(e)}
              ></input>
              <svg
                className="absolute w-5"
                style={{ top: "12px", left: "10px" }}
                viewBox="0 0 56.966 56.966"
              >
                <path
                  d="M55.146 51.887L41.588 37.786A22.926 22.926 0 0 0 46.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 0 0 .083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z"
                  fill="#808080"
                ></path>
              </svg>
              <div class="mt-2 md:mt-0 cursor-pointer">
                <div onClick={(e)=> onClickHandler(e)} className="vc-colored-btn">
                  Search
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2">
            <img
              className="img-responsive m-auto mr-0 pt-5"
              src="https://res.cloudinary.com/vantagecircle/image/upload/v1641551495/gatsbycms/uploads/2022/01/Docs-HP-hero-IMG.png"
              alt="Vantage Circle Docs"
            />
          </div>
        </div>
      </div>
      <section className="py-10 md:py-10 md:pb-0">
        <div className="container max-w-5xl mx-auto px-6">
          <div className="text-center">
            <h2 className="section-title">
              <span className="text-orange">Browse</span> Help Topics
            </h2>
          </div>
        </div>
        <div className="container max-w-6xl mx-auto px-6 mt-10 md:mt-20">
          <div className="flex flex-wrap items-center justify-center mb-20">
            <div className="lg:w-1/2">
              <p className="section-label">EMPLOYEE RECOGNITION & REWARDS</p>
              <h3 className="text-gray-900 text-xl md:text-2xl font-bold mb-2">
                Vantage Recognition
              </h3>
              <p className="text-sm md:text-base">
                Learn more about automating your employee recognition and program with Vantage Recognition easy-to-use and customizable cloud-based solution. Stay up to date with features like automated rewarding, on-spot recognition, peer-to-peer recognition, budgeting and allocation, management dashboard, and
                detailed analytics.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex">
                <div className="mt-3 sm:mt-0">
                  <a href="/vantage-recognition/" className="vc-colored-btn">
                    Explore Articles
                  </a>
                </div>
              </div>
            </div>
            <div className="pl-0 mt-10 sm:mt-0 sm:pl-5 md:pl-10 lg:w-1/2">
              <div className="justify-center flex flex-wrap relative text2">
                <div className="plectrum-bg plectrum-bg-home mx-auto">
                  <img
                    className="img-responsive"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/vrewards-all.png"
                    alt="Vantage Recognition"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap flex-row-reverse items-center justify-center mb-20">
            <div className="pl-0 sm:pl-5 md:pl-10 lg:w-1/2">
              <p className="section-label">EMPLOYEE BENEFITS</p>
              <h3 className="text-gray-900 text-xl md:text-2xl font-bold mb-2">
                Vantage Perks
              </h3>
              <p className="text-sm md:text-base">
                Explore insightful articles on how to use a dedicated perks
                platform where your employees can explore exclusive deals and
                discounts. With the help of Vantage Perks, the employees can
                minimize their financial burden and experience a whole new level
                of shopping online.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex">
                <div className="mt-3 sm:mt-0">
                  <a href="/vantage-perks/" className="vc-colored-btn">
                    Explore Articles
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-10 sm:mt-0 lg:w-1/2">
              <div className="justify-center flex flex-wrap relative text2">
                <div className="plectrum-bg plectrum-bg-home mx-auto">
                  <img
                    className="img-responsive"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/vperks.png"
                    alt="Vantage Perks"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center mb-20">
            <div className="lg:w-1/2">
              <p className="section-label">EMPLOYEE SURVEY</p>
              <h3 className="text-gray-900 text-xl md:text-2xl font-bold mb-2">
                Vantage Pulse
              </h3>
              <p className="text-sm md:text-base">
                Explore the characteristic peculiarities of survey creation,
                from building questionnaires, to distributing, to analyzing and
                reporting results. Learn about gathering feedback on different
                stages of an employee’s employment lifecycle and drive better
                employee engagement by implementing the possible suggestions.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex">
                <div className="mt-3 sm:mt-0">
                  <a href="/vantage-pulse/" className="vc-colored-btn">
                    Explore Articles
                  </a>
                </div>
              </div>
            </div>
            <div className="pl-0 mt-10 sm:mt-0 sm:pl-5 md:pl-10 lg:w-1/2">
              <div className="justify-center flex flex-wrap relative text2">
                <div className="plectrum-bg plectrum-bg-home mx-auto">
                  <img
                    className="img-responsive"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/vpulse.png"
                    alt="Vantage Pulse"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap flex-row-reverse items-center justify-center mb-20">
            <div className="pl-0 sm:pl-5 md:pl-10 lg:w-1/2">
              <p className="section-label">EMPLOYEE WELLNESS</p>
              <h3 className="text-gray-900 text-xl md:text-2xl font-bold mb-2">
                Vantage Fit
              </h3>
              <p className="text-sm md:text-base">
                Stay up-to-date with new functionalities and features of the
                AI-Powered Corporate wellness solution that would enhance
                employee wellbeing and productivity. Learn about creating
                different gamified challenges & contests, integrating Vantage
                Fit with different wearables, and a lot of features that you can
                explore.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex">
                <div className="mt-3 sm:mt-0">
                  <a href="https://help.vantagefit.io/" className="vc-colored-btn">
                    Explore Articles
                  </a>
                </div>
              </div>
            </div>
            <div className="mt-10 sm:mt-0 lg:w-1/2">
              <div className="justify-center flex flex-wrap relative text2">
                <div className="plectrum-bg plectrum-bg-home mx-auto">
                  <img
                    className="img-responsive"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2021/06/vfit.png"
                    alt="Vantage Fit"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-center">
            <div className="lg:w-1/2">
              <p className="section-label">VANTAGE POINTS</p>
              <h3 className="text-gray-900 text-xl md:text-2xl font-bold mb-2">
                Vantage Redemption
              </h3>
              <p className="text-sm md:text-base">
               Explore helpful articles on how an employee can take advantage of their yearly anniversary and explore numerous benefits that will elevate their work experience. With Vantage Redemption employees can now leverage a plethora of redemption options of their choice. 
              </p>
              <div className="mt-5 sm:mt-8 sm:flex">
                <div className="mt-3 sm:mt-0">
                  <a href="/vantage-redemption/" className="vc-colored-btn">
                    Explore Articles
                  </a>
                </div>
              </div>
            </div>
            <div className="pl-0 mt-10 sm:mt-0 sm:pl-5 md:pl-10 lg:w-1/2">
              <div className="justify-center flex flex-wrap relative text2">
                <div className="plectrum-bg plectrum-bg-home mx-auto">
                  <img
                    className="img-responsive"
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/v1651235494/gatsbycms/uploads/2022/04/vantage-points-redeem.png"
                    alt="Vantage Redemption"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
